<template lang="pug">
//- loadingOverlay(v-show='loading' message='Sending Email . . .' :zIndex='999')
#smsTemplateDialog
        v-card(v-if='!messageDialog')
            v-card-title.d-flex.justify-center {{$t('SMSCAMPAIGNDIALOG.TITLE')}}
            v-card-text
                v-form(v-model="valid" ref="form")
                    v-textarea.mb-3.textarea-style(v-model="template" rows='10' :label=`$t('SMSCAMPAIGNDIALOG.SMS_CONTENT')`, large="large" hide-details="auto" :readonly='true')
            v-card-actions(v-if='!is_ai').px-2.py-4
                v-row.d-flex.justify-center
                    v-col.pr-2(cols="3")
                        v-btn(:ripple="false" @click="onCancel" width="100%" height="40" color="cancel" depressed dark) {{$t('GENERAL.CANCEL')}}
                    v-col.pl-2(cols="3")
                        v-btn(:ripple="false" @click="sendSMS" width="100%" height="40" color="green" depressed dark) {{$t('SMSEDITOR.SEND')}}
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
</template>

<script>
	import Vue from 'vue';
    import { VueEditor } from "vue2-editor";
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import TwilioService from "@/services/TwilioService";
    import EmailTemplateDataService from "../../services/EmailTemplateDataService";
    import SegmentDataService from "@/services/SegmentDataService";
    import CampaignDataService from "@/services/CampaignDataService";
    import messageDialog from '@/components/Dialog/redirectDialog';
    import i18n from '/common/plugins/vue-i18n.js' 

	export default Vue.extend({
        components: {
            VueEditor,
            loadingOverlay,
            messageDialog
        },
        props: {
            smsData: {
                type: Object,
                required: true,
            },
            addSegmentData: {
                type: Object,
                required: false,
            },      
            addCampaignData: {
                type: Object,
                required: false,
            },
            is_ai:{
                type:Boolean,
                required: false,
            }
        },
        data() {
            return {
                valid: true,
                editorOptions: {
                    modules: {
                        toolbar: false,
                    },
                },
                loading: false,
                objectType: 'contact',
                objectDetail: {
                    'id': this.smsData.contacts[0].id,
                },
                template: '',
                messageDialog:false,
                message:"",
            }
        },
        created() {
            if(this.is_ai){
                this.template = this.smsData.body;
                return;
            }
            EmailTemplateDataService.transformEmail(this.objectType, this.objectDetail.id, this.smsData.body)
            .then(response => {
                this.template = response.data;
            });
        },
        methods: {
            onCancel() {
                this.$emit('emitSMSCampaignDialog', false);
            },
            async createSegmentCampaign(){
                this.addSegmentData.id = 0;
                await SegmentDataService.addSegment(this.addSegmentData)
                .then(response => {            
                    this.segmentDetail = response.data
                });  
                this.addCampaignData.segment = {"id": this.segmentDetail.id}                
                await CampaignDataService.create(this.addCampaignData)
                    .then(response => {
                        this.campaginDetail = response.data;
                        this.smsData.campaign_id = this.campaginDetail.id,
                        this.smsData.campaign_name = this.campaginDetail.name                        
                });           
            },
            async sendSMS() {
                this.loading = true;
                if(this.addSegmentData){
                   await(this.createSegmentCampaign() )
                }  
                // let toNumber = [];
                // this.smsData.contacts.forEach(contact => {
                //     toNumber.push(contact.mobile_phone);
                // });
                let data = {
                    "campaign": {
                        "id": this.smsData.campaign_id,
                        "name": this.smsData.campaign_name,
                    },
                    "body": this.smsData.body,
                    "contacts": this.smsData.contacts
                }
                TwilioService.sendSMS(data);
                if(this.addSegmentData){
                   this.showMessageDialog()
                }
                else
                {
                    this.$emit('emitSMSCampaignDialog', true);
                }
            },
            showMessageDialog() {                
                this.messageDialog = true;
                this.message = i18n.t('SMSCAMPAIGNDIALOG.MESSAGE');
            },
            async onEmitMessageDialog(val) {
                if(val)
                {
                    this.$router.push('/Campaign/'+this.campaginDetail.id)
                    this.messageDialog = false; 
                    this.$router.go();
                }  
                else{
                    this.messageDialog = false;
                    this.$emit('emitSMSCampaignDialog', true);
                }             
            },
        }
	});
</script>

