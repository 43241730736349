<template lang="pug">
    #emailCampaignDialog
        v-card(v-if='!messageDialog')
            v-card-title.d-flex.justify-center {{$t('EMAILCAMPAIGNDIALOG.TITLE')}}
            v-card-text
                v-form(v-model="valid" ref="form")
                    v-text-field.mb-3(v-model="emailData.subject" :label=`$t('EMAILCAMPAIGNDIALOG.SUBJECT')` large="large" hide-details="auto" :readonly='true')
                    v-text-field.mb-3(v-model="attachments" :label=`$t('EMAILCAMPAIGNDIALOG.ATTACHMENT')` large="large" hide-details="auto" :readonly='true')
                    vue-editor(v-model='template' :disabled='true' :editorOptions='editorOptions')
            v-card-actions(v-if='!is_ai').px-2.py-4
                v-row.d-flex.justify-center
                    v-col.pr-2(cols="3")
                        v-btn(:ripple="false" @click="onCancel" width="100%" height="40" color="cancel" depressed dark) {{$t('GENERAL.CANCEL')}}
                    v-col.pl-2(cols="3")
                        v-btn(:ripple="false" @click="sendEmail" width="100%" height="40" color="green" depressed dark) {{$t('SMSEDITOR.SEND')}}
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
</template>

<script>
    import Vue from 'vue';
    import { VueEditor } from "vue2-editor";
    import O365Service from "@/services/O365Service";
    import EmailTemplateDataService from "../../services/EmailTemplateDataService";
    import SegmentDataService from "@/services/SegmentDataService";
    import CampaignDataService from "@/services/CampaignDataService";
    import messageDialog from '@/components/Dialog/redirectDialog';
    import i18n from '/common/plugins/vue-i18n.js' 

    export default Vue.extend({
        components: {
            VueEditor,
            messageDialog
        },
        props: {
            emailData: {
                type: Object,
                required: true,
            },
            addSegmentData: {
                type: Object,
                required: false,
            },      
            addCampaignData: {
                type: Object,
                required: false,
            },
            is_ai:{
                type:Boolean,
                required: false,
            },
            att_placeholder:{
                type:String,
                required: false,
            }
        },  
        data() {
            return {
                valid: true,
                editorOptions: {
                    modules: {
                        toolbar: false,
                    },
                },
                userEmail: '',
                objectType: 'contact',
                objectDetail: {
                    'id': this.emailData.contacts[0].id,
                },
                template: '',
                attachments: [],
                messageDialog:false,
                message:"",
            }
        },
        created() {
            this.getLoginUserEmail();
            this.emailData.attachments.forEach(item => {
                this.attachments.push(item.name);
            });
            if(this.is_ai){
                this.template = this.emailData.body;
                if(this.attachments.length==0){
                    this.attachments.push(this.att_placeholder);
                }
                return;
            }
            EmailTemplateDataService.transformEmail(this.objectType, this.objectDetail.id, this.emailData.body)
            .then(response => {
                this.template = response.data;
            });
        },
        methods: {
            onCancel() {
                this.$emit('emitEmailCampaignDialog', false);
            },
            async createSegmentCampaign(){
                this.addSegmentData.id = 0;
                await SegmentDataService.addSegment(this.addSegmentData)
                .then(response => {            
                    this.segmentDetail = response.data
                });  
                this.addCampaignData.segment = {"id": this.segmentDetail.id}
                await CampaignDataService.create(this.addCampaignData)
                .then(response => {
                    this.campaginDetail = response.data;
                    this.emailData.campaign_id = this.campaginDetail.id;
                    this.emailData.campaign_name = this.campaginDetail.name;
                    this.campaignID = this.campaginDetail.id;
                });
            },
            async sendEmail() {
                if(this.addSegmentData){
                   await(this.createSegmentCampaign())
                }                
                this.loading = true;
                let toAddressee = [];
                let toCopy = [];
                let formData = new FormData();
                formData.append('sender', this.userEmail);
                formData.append('recipient', JSON.stringify({ "to": toAddressee, "cc": toCopy }));
                formData.append('subject', this.emailData.subject);
                formData.append('body', this.emailData.body);
                formData.append('direction', 1);    // 1 - Send
                formData.append('message_id', 'SOMEID');

                formData.append('campaigns[0]id', this.emailData.campaign_id);
                formData.append('campaigns[0]name', this.emailData.campaign_name);
                let n = 0;
                this.emailData.contacts.forEach(contact => {
                    formData.append(`contacts[${n}]id`, contact.id);
                    n ++;
                });

                for (let i=0; i<this.emailData.attachments.length; i++) {
                    formData.append(`emailattachment_set[]`, this.emailData.attachments[i]);
                }
                
                O365Service.sendEmail(formData);
                if(this.addSegmentData){
                   this.showMessageDialog()
                }
                else
                {
                    this.$emit('emitEmailCampaignDialog', true);
                }
            },
            getLoginUserEmail() {
                O365Service.getO365Accounts()
                .then(response => {
                    this.userEmail = response.data[0]? response.data[0].o365_user_principal_name:null;
                });

                // var user = window.localStorage.getItem('user');
                // if (user != null && user != undefined) {
                //     let obj = JSON.parse(user);
                //     return obj.email;
                // } else {
                //     return '';
                // }
            },
            showMessageDialog() {                
                this.messageDialog = true;
                this.message = i18n.t('EMAILCAMPAIGNDIALOG.MESSAGE');
            },
            async onEmitMessageDialog(val) {
                if(val)
                {                    
                    this.$router.push('/Campaign/'+this.campaginDetail.id)
                    this.messageDialog = false; 
                    this.$router.go();
                }  
                else{
                    this.messageDialog = false;
                    this.$emit('emitEmailCampaignDialog', true);
                }             
            },
        }
    });
</script>

